

























import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {Component, Mixins} from "vue-property-decorator";

@Component({})
export default class extends Mixins(ComponentBasic) {
    LN: string = "[NavigationAdmin]::";

    OnRefresh() {
        this.LoadSettings()
    }
}
